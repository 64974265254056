.interest-section-container {
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f7f7f7;
}

.section-containter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $sectionbg;
}

.interest {
  hr {

  }
  col {
    margin: 0px;
    padding: 0px;
  }
  // background-color: $sectionbg;
  margin: 0 auto;
  padding-top: 50px;
  // margin-top: 50px;
  // margin-left: 10px;
  // margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  // max-width: 1100px;
  // padding: 50px;
  padding-bottom: 0px;
  // margin: 0 auto;
  // margin-top: 20px;
  // margin-bottom: 50px;
  max-width: 920px;
  // background-color: rgba(255, 255, 255, 0.297);

  .content-wrapper {

    display: flex;
    justify-content: center;
    text-align: left;
  }

  .exp-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // border-radius: 200px;
    // border: 1px solid aliceblue;
    margin-bottom: 20px;

  }
  h3 {
    font-size: 22px;
    margin-bottom: 5px;
    font-family: 'Roboto';
  }
  h4 {
    font-size: 18px;
    color: $redText;
    font-family: 'Roboto', sans-serif;
  }
  svg {
    fill: $blue1;
    height: 1.2em;
    width: 1.5em;
  }

  h5 {
    font-size: 1.2rem;
  }

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #5B5B5B;
    color: $darkText;
  }



img {
  max-width: 220px;
  // margin-top: 32px;
  text-align: right;
  border-radius: 200px;
  box-shadow: 0px 0px 0px 10px #FCD462;
}
}

.hide-section {
  display: none !important;
}

.gallery-wrapper {
  // display: flex;
  // width: 100vw;
  // align-items: center;
  // justify-content: center;
  // max-width: 900px;
  text-align: center;
  // margin: 0px 50px;
  // margin-right: 14vw;
  // margin-left: 14vw;
  padding-bottom: 50px;

.button-36 {
  margin-top: 10px;
  // background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  // background-image: linear-gradient(92.88deg, #b59745 9.16%, #ccb743 43.89%, #d7c53f 64.72%);
  background-color: $yellow1;
  border-radius: 32px;
  border-style: none;
  box-sizing: border-box;
  // color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  // font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 2.1rem;
  padding: 0 1.2rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: $yellow1 0 1px 5px;
  // transition-duration: .1s;
  transform: scale(1.01);
}
  .react-photo-gallery--gallery{
    margin: 0 auto;
    max-width: 920px;
    img {
      object-fit: cover;
    }
  }
}

.button-36 {
  margin-top: 10px;
  // background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  // background-image: linear-gradient(92.88deg, #b59745 9.16%, #ccb743 43.89%, #d7c53f 64.72%);
  background-color: $yellow1;
  border-radius: 32px;
  border-style: none;
  box-sizing: border-box;
  // color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  // font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 500;
  // height: 2.1rem;
  // padding: 0 1.2rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: $yellow1 0 1px 5px;
  // transition-duration: .1s;
  transform: scale(1.01);
}