* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  color: #1a1a1a;
  // color: rgb(46, 46, 46);
  // color: white;

}

.site-wrapper {

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // max-height: 100vh;
  // background: radial-gradient(77.95% 77.95% at 74.66% 58.07%, rgba(255, 254, 220, 0.8) 0%, rgba(255, 255, 255, 0.152) 62.28%, rgba(255, 255, 255, 0) 100%), radial-gradient(89.67% 70.39% at 93.75% 92.16%, #29C2D7 0%, rgba(144, 160, 215, 0.49) 52.46%, rgba(255, 156, 156, 0) 100%), radial-gradient(68.86% 68.86% at 94.55% 1.7%, #FADC90 0%, rgba(250, 220, 144, 0) 100%), linear-gradient(130.87deg, rgba(245, 115, 122, 0.78) 3.47%, rgba(245, 115, 122, 0) 77.25%); background-blend-mode: overlay, normal, normal, normal, normal, normal;
  // background-color: #F7FAFC;
  // background-color: transparent;
  // background-image: url('../assets/HeroBack.png');
  // background: url('../assets/background.jpeg');
  // background: linear-gradient(rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.1)) url('../assets/background.jpeg');
  // background: url('../assets/back.jpeg');
  // background: url('../assets/3.gif');
  // background-color: black;
  // filter: contrast(1.5);
  // background-color: #f6f6f6;
  // background-color: #f5f5f5;
  // background-color: #FBFBFB;
  // background-color: #F8F3E9;
  background-color:$sectionbg;
  // background-color: rgba(255, 250, 240, 1);
  background-size: cover;
  // background-size:contain;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  // backdrop-filter: blur(50px);
  // filter: blur(10px);
  main {
    flex-grow: 1;
    // background-color: rgba(255, 255, 255, 0.6);
    // backdrop-filter: blur(90px);
    filter: contrast(1);
  }

  .section-header {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 30px;
    // color: $darkblue1;
    // color: $red1;
    font-family: 'Roboto';
    // font-family: 'PT Sans';
    // width:fit-content;
    // background-image: linear-gradient(120deg, #ec004f,#fc6767 100%);
    // background-repeat: no-repeat;
    // background-size: 98% 0.05em;
    // background-position: 20% 100%;
    // transition: background-size 0.25s ease-in-out;
    text-transform: uppercase;
    
    h1 {
    }
    .fancy-undeline {
      background-image: linear-gradient(120deg, #ec008c, #fc6767 100%);
      background-repeat: no-repeat;
      background-size: 100% 0.1em;
      background-position: 0 100%;
      transition: background-size 0.25s ease-in;
    }

    // &:hover {
    //   background-image: linear-gradient(120deg, #ec006a55, #fc676783 100%);
    //   background-size: 100% 88% !important;
    // }

  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-bottom: 100px;
    // background-color: rgba(255, 255, 255, 0.297);
    // background-color: rgba(255, 255, 255, 0.721);
    padding: 10px;
    // text-align: left;
  }
}

html {
  // scroll-behavior: smooth;
}

.header-wrapper {
  width: 100%;
  text-align: left;
  // border-bottom: blue;
}