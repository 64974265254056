.navbar{
  background-color: #E0ECF3;
  // background-color: white;
  // background-color: transparent;
  position: sticky;
  top: 0;
  z-index: 2;
  .logo {
    color: white;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: white;
    color: $greyText;
    font-weight: 600;
    &:hover {
      color: $greyText;
      text-decoration: underline;
      // font-size: 1.05rem;
    }
  }
}
.navigation {
  position: sticky;
  top: 0;
}