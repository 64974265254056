.footer {
  // background-color: $darkText;
  // background-color: rgba(255, 255, 255, 0.297);
  // background-image: url('../assets/skills.svg');
  // background-color: $yellow1;
  background-color: #f7f7f7;
  padding: 50px;
  padding-bottom: 0px;
  // color: white;
  h4 {
    font-weight: bold;
    font-family: 'Roboto';
    text-transform: uppercase;
    // color: white;
  }
  p {
    margin-top: 20px;
    font-size: 1rem;
    // color: white;
  }
  a {
    text-decoration: none;
    height: 40px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i {
    font-size: 32px;
    margin: 10px;
    transition: 0.3 ease;
    &:hover {
      font-size: 34px;
    }
    
  }

  span {
    margin-top: 20px;
  }
  svg {
    height: 40px;
    width: 40px;
  }

  .contact-links{
    display: flex;

    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .credit {
    font-size: 0.8rem;
  }
}