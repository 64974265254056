.project-section-container {
  padding-top: 60px;
  background-color: #f7f7f7;
}

.projects-new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 80vw;
  max-width: 1550px;
  max-width: 1100px;
  padding: 10px;
  margin: 0 auto;
  // border: 1px solid black;
  // background-color: white;
  border-radius: 10px;
  // background-image: url('../assets/skills.svg');
  background-size: cover;

  // margin-top: -200px;
  // margin-top: 60px;
  .all-projects {
    // gap: 20px;
    margin: 0px;
    align-items: center;
    justify-content: space-between;
  }

  .col {
    padding: 0px;
  }

  .project-wrapper {
    // border: 1px solid black;
    margin: 0px;
    // min-height: 360px;
    padding: 30px;
    // max-width: 98%;
    // margin-left: 10px;
    // margin-right: 10px;
    // overflow: hidden;
    justify-content: center;
    // align-items: center;
    // margin: 0 auto;
    margin-bottom: 50px;
    // border: 1px solid #d6e5ef;
    // border: 1px solid rgb(230, 236, 248);
    // border: 1px solid rgba(245, 53, 88, 0.3);
    // border: 1px solid $red1;
    // box-shadow: 0px 0px 20px 5px #b3bcc251;
    border-radius: 30px;
    // background-color: #fdfdfd;
    // background-color: #F8F8F8;
    background-color: white;
    // opacity: 1;
    // background-image: linear-gradient(#f7f7f7 1px, transparent 1px), linear-gradient(to right, #f7f7f7 1px, #fdfdfd 1px);
    // background-size: 20px 20px;






    // background-color: rgba(255, 255, 255, 0.297);
    // background-color: rgba(255, 255, 255, 0.949);
    // backdrop-filter: blur(15px);
    // background-color: #fff6cc;
    // background-color: #0a2836;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    // box-shadow: rgba(245, 53, 88, 0.3) 0px 1px 3px 0px, rgba(245, 53, 88, 0.1) 0px 1px 2px 0px;
    width: 100%;

    .buttons {
      display: flex;
      gap: 20px;

      a {
        button {
          border-radius: 15px;
          border: 2px solid #546B77;
          border: 2px solid transparent;
          // background-color: #FCD462;
          // box-shadow: 0px 2px 0px 0px #F6C358;
          background-color: white;
          // background-color: #BA5650;
        
          background-color: $yellow1;
          // background-color: $yellow2;
          // background-color: #8A3746;
          // background-color: $orange1;
          // background-color: $blue1;
          // background-color: $lightblue1;
          // background-color: $redText;
          width: 100%;
          transition: 0.2s ease-in-out;
          padding-left: 15px;
          padding-right: 15px;
          // border-radius: 100rem;
          // color: white;
          // font-weight: 300;
          // font-family: 'PT Sans';
          // font-weight: bold;

          // padding: 5px;
          &:hover {
            // background-color: #F6C358;
            // background-color: #546B77;
            // background-color: #8A3746;
            // background-color: $yellow2;
            // color: white;
            // box-shadow: 0px 2px 0px 0px #FCD462;
            transform: scale(1.05);
            box-shadow: $yellow1 0 1px 5px;
            // transition-duration: .1s;
            transform: scale(1.01);
          }
        }
      }
    }

    .image-wrapper {
      // border: 1px solid black;
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // width: 180px;
      // height: 210px;
      // height: 100%;
      // border: 1px solid blue;
      // min-height: 350px;
      // max-height: 200px;
      height: 350px;
      ;
      // max-height: 100%;


      .overlay {
        // display: none;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #0f0f0f60;
        overflow: hidden;
        width: 180px;
        height: 210px;
        height: 0%;
        // transition: 0.5s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;


        button {
          background-color: #0f0f0fcd;
          // font-weight: 300;
          font-size: 0.8rem;
          width: 70px;
          color: white;
          padding: 5px;
          // padding-bottom: 1rem;
          border: 1px solid transparent;
          border-radius: 5px;
          margin-bottom: 20px;
          transition: 0.3 ease;
          font-size: 22px;

          i {
            color: white;
            font-size: 22px;
          }

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      &:hover .overlay {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 20px;
        height: 100%;
        cursor: pointer;
      }
    }

    img {
      // max-width: 220px;
      // width: 200px;
      // height: 230px;
      border-radius: 10px;
      object-fit: cover;
      object-position: top;
      // margin-top: 32px;
      text-align: right;
    }

    video {
      // width: 180px;
      // display: none;
      // max-height: 250px;
      max-height: 85%;
      // height: 100%;
      width: 90%;
      object-fit: cover;
      object-position: top;
      border-radius: 15px;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.293);
      // box-shadow: rgba(50, 50, 105, 0.15) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
      // border: 10px solid #E0ECF3;
      // border: 10px solid #B9D4AE;
    }

    .info-wrapper {
      // margin-left: 15px;
      // background-color: white;
      // background-color: rgba(255, 255, 255, 0.421);
      // background-color: #E0ECF3;
      // background-color: #B9D4AE;

      // border: 1px solid rgb(230,236,248);
      // box-shadow: 0px 2px 20px 0px lightgray;
      // box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.293);
      border-radius: 0px 10px 10px 0px;
      border-radius: 10px;
      padding: 0px;
      padding-right: 30px;
      padding-left: 0px;
      // min-height: 350px;

      p {
        font-size: 0.95rem;
      }

      h4 {
        font-weight: 600;
      }

      ul {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
      }

      li {
        list-style: none;
        display: inline;
        font-size: 32px;
        margin-right: 15px;

      }


    }
  }
}

#projectWrap {
  // padding: 0px;
  // background-color: black;
  // margin: 0px;
  width: 100%;
}



.reverse {
  // text-align: right !important;
  margin-left: 0px !important;
  margin-right: 15px !important;
  padding-left: 30px !important;
  padding-right: 40px !important;
  border-radius: 10px !important;

  // margin-right: 15px !important;
}

.animovies {
  background-image: url('../assets/animovies.png');
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.409), rgba(0, 0, 0, 0.477)), url('../assets/animovies.png');
  background-size: cover;
  background-position: top;
}

.aromatics {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.409), rgba(0, 0, 0, 0.477)), url('../assets/aromatics.png');
  background-size: cover;
  background-position: top;
}

.formula1 {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.409), rgba(0, 0, 0, 0.477)), url('../assets/f1.png');
  background-size: cover;
  background-position: top;
}

.tetris {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.409), rgba(0, 0, 0, 0.477)), url('../assets/tetris.png');
  background-size: cover;
  background-position: top;
}

.info-wrapper {
  // background-color: white;
}



.info-container {
  justify-content: center;

  // align-items: center;
  padding: 0px;
  // min-height: 200px;
  display: flex;
  flex-direction: column;

  // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  .info-wrapper {
    margin-left: 15px;
    h4 {
      color: $blue1;
      // color: $orange1;
      // color: dark($orange1);
      // color: $redText;
    }
  }
}




// <!-- HTML !-->
// <button class="button-57" role="button"><span class="text">Button 57</span><span>Alternate text</span></button>

/* CSS */
.button-57 {
  position: relative;
  overflow: hidden;
  border: 1px solid #18181a;
  color: #18181a;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  padding: 18px 18px 17px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-57 span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button-57:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button-57:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button-57:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}