.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  // padding-bottom: ;
  // background-color: rgb(248, 248, 248);
}

.section-wrapper {
  text-align: left;
}


.about {
  // position: relative;
  // background-color: rgb(248, 248, 248);
  // margin-top: 200px;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  // background-color: blue;
  // border-radius: 10px;
  // box-shadow: 0px 10px 20px 0px ;
  // max-width: 1100px;
  // padding: 50px;
  // padding-bottom: px;
  // margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  // background-color: rgba(255, 255, 255, 0.297);
  .content-wrapper {
    // max-width: 1100px;
    width: 75%;
    max-width: 1100px;
    // max-width: 3000px;
    border-radius: 10px;
    // border: 3px solid #F7FAFC;
    // box-shadow: 0px 5px 20px 0px #edf1f4;
  }
  .image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
  }

  .text-wrapper {
    // max-width: 600px;
    h4 {
      font-size: 20px;
      color: $redText;
      font-family: 'Roboto', sans-serif;
    }

    h1 {
      font-weight: 800;
      font-size: 80px;
      line-height: 96px;
      color: #FFFFFF;
    }

    p {
      font-family: 'Lato';
      // font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #5B5B5B;
      // color: $darkText;
    }

  }

  img {
    max-width: 220px;
    // margin-top: 32px;
    text-align: right;
    border-radius: 100px;
    border-radius: 100rem;
    // border-radius: 32px;
    // box-shadow: 0px 0px 0px 10px #FCD462;
    // box-shadow: 0px 0px 0px 10px $redText;
    // box-shadow: 0px 0px 0px 10px $redText;
    // box-shadow: 0px 0px 0px 2px white;
    // border: 2px solid white;
    // box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px inset , rgb(255, 255, 255) 5px -5px 0px -2px, $yellow1 5px -5px;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 6px, $yellow1 0px 0px 0px 10px;
    // filter: grayscale(60%);
    filter: contrast(0.95);
  }
}

.background {
  width: 100%;
  height: 500px;
  // background-color: blue;
  background-image: url('../assets/aboutbg.png');
  background-position: top;
  background-size: cover;
}





@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}

