$redText: #BE3A1D;
$greyText:#5B5B5B;
$darkText: #1a1a1a;

$yellow1 : #FFB703;
$yellow2 : #de9f00;
$orange1 : #FB8500;
$blue1 : #219EBC;
$lightblue1 : #8ECAE6;
$darkblue1 : #023047;
$red1 : #F53558;

$sectionbg: rgba(233, 233, 233, 0.134);
