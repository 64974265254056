.vertical-timeline-element-content {
  border-radius: 15px !important;
  // border-radius: 5px 5px 32px 32px !important;
  box-shadow: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.09) 0px 1px 3px 0px !important;
  
}

.vertical-timeline-element {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.vertical-timeline-element-icon {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
  // box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px, $yellow1 0px 0px 0px 3px !important;

  svg {
    fill: white;
  }
}