.hero {
  // position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // max-width: 900px;
  // background-image: url('../assets/HeroBack.png');
  // background-image: url('../assets/HeroBack2.png');
  // background: radial-gradient(77.95% 77.95% at 74.66% 58.07%, rgba(255, 254, 220, 0.8) 0%, rgba(255, 255, 255, 0.152) 62.28%, rgba(255, 255, 255, 0) 100%), radial-gradient(89.67% 70.39% at 93.75% 92.16%, #29C2D7 0%, rgba(144, 160, 215, 0.49) 52.46%, rgba(255, 156, 156, 0) 100%), radial-gradient(68.86% 68.86% at 94.55% 1.7%, #FADC90 0%, rgba(250, 220, 144, 0) 100%), linear-gradient(130.87deg, rgba(245, 115, 122, 0.78) 3.47%, rgba(245, 115, 122, 0) 77.25%); 
  // background-color: linear-gradient(130.87deg, rgba(245, 115, 122, 0.78) 3.47%, rgba(245, 115, 122, 0) 77.25%);
  // background-image: url('../assets/heroback3.png');
  background-image: url('../assets/heroback5.png');

  // background-image: url('../assets/heroback7.png');
  // background-image: url('../assets/heroback8.png');
  // background-image: url('../assets/frame20.png');
  // background-image: url('../assets/heroback9.png');
  // background-image: url('../assets/heroback10.png');
  // background-image: url('../assets/heroback13.png');
  // background-image: url('../assets/heroback15.png');
  // background-image: url('../assets/heroback55.png');
  

  // background-color: linear-gradient(rgba(113, 145, 188, 1), rgba(167, 186, 212, 1));
  // background-color: rgb(248, 248, 248);

  // background-color: linear-gradient(179.58deg, rgba(41, 90, 156, 0.62) 0.36%, #A7BAD4 85.46%, #A7BAD4 85.46%);
  // background-image: linear-gradient(rgba(72, 112, 169, 0.308) 0.36%,rgba(167, 186, 212, 0.908)4 85.46%, #A7BAD4 85.46%), url('../assets/heroback3.png');
  // background: linear-gradient(rgba(72, 113, 169, 0.62) 0.36%, #A7BAD4 85.46%, #A7BAD4 85.46%);

  // background-color: linear-gradient();
  // background-image: url('../assets/heroback4.png');
  background-repeat: repeat;
  background-position: top;
  // background-size: auto 100%;
  background-size:cover;
  min-height: 100vh;
  // min-height: calc(100vh - 80px);
  // min-height: calc(100vh - 400px);
  // min-height: calc(100vh + 400px);
  min-height: calc(100vh + 650px);

  padding: 50px;
  padding-bottom: 0px;
  overflow: visible;

  margin: 0 auto;
  // z-index: 1;
  position: relative;


  .content-wrapper {
    margin-top: -1000px;
    // margin-top: -350px;
    // height: 400px;
    max-width: 800px;
    // border: 1px solid black;
    .col{
      overflow-x:visible;
    }
  }

  .text-wrapper {
    height: 100%;
    max-width: 800px;
    margin-left: 35px;
    .hello {
      overflow-x: hidden;
    }
   
    // overflow-x:visible;
    h4 {
      font-size: 20px;
      color: $redText;
      font-family: 'Roboto', sans-serif;
      animation: 1s ease-out 0s 1 slideInFromLeft;
      overflow:hidden
    }

    h1 {
      font-weight: 800;
      font-size: 128px;
      font-size: calc(72px + 2vw);
      color: #ffffff;
      color: $darkText;

    }
    .header {
      animation: 1.7s ease-out 0s 1 slideInFromLeft;
      font-weight: 800;
      font-size: 128px;
      font-size: calc(72px + 2vw);
      color: #ffffff;
      // color: $darkText;
      color: $darkblue1;
      // color: $redText;
      // color: $redText;

    }
    .lastname {
      // font-size: 74px;
      // overflow-y: hidden;
      animation: 1.5s ease-out 0s 1 slideInFromBottom;

    }
    div {
      // margin-right: -500px;
      // max-height: 200px;
      overflow-y:hidden;
    }
    p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      // color: #5B5B5B;
      color: #1a1a1a;

    }

  }
  img{
    max-width: 125px;
    margin-top: 32px;
    text-align: right;
  }
}

.nav-links {
  // position: absolute;

  z-index: 1;
  background-color: white;
  background-color: #ECE8DD;
  background-color: #F8F4EC;
  background-color: #ECE2D2;
  // border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: center;
  column-gap: 40px;
  flex-wrap: wrap;
  margin: 0 auto;
  // width: 100%;
  // margin-top: -80px;
  // max-width: 900px;
  // height: 80px;
  text-align: center;
  // z-index: -1;
  
  a {
    text-decoration: none;
    // color: white;
    font-weight: 600;
    transition: .3s ease;
    font-size: 1rem;
    color: gray;
    color: $darkText;
    &:hover{
      color: $redText;
      font-size: 1.05rem;
      // transform: scale(1.03);
    }
  }

}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(+200%);
  }
  100% {
    transform: translateY(0%);
  }
}

.sun {
  text-align: right !important;
  // height: 100px !important;
  width: 100%;
justify-content: flex-end;
}

.back {
  position: absolute;
  // background-color: blue;
  width: 100vw;
  height: 100vh;
  // min-height: calc(100vh + 650px);
  z-index: -9999;
  // background: linear-gradient(130.87deg, rgba(245, 115, 122, 0.78) 3.47%, rgba(245, 115, 122, 0) 77.25%);
  // background: linear-gradient(rgba(113, 145, 188, 1), rgba(167, 186, 212, 1));
  // background: linear-gradient(rgba(72, 113, 169, 0.62) 0.36%, #A7BAD4 85.46%, #A7BAD4 85.46%);

  background-image: url('../assets/back.jpeg');
  // background: radial-gradient(77.95% 77.95% at 74.66% 58.07%, rgba(255, 254, 220, 0.8) 0%, rgba(255, 255, 255, 0.152) 62.28%, rgba(255, 255, 255, 0) 100%), radial-gradient(89.67% 70.39% at 93.75% 92.16%, #29C2D7 0%, rgba(144, 160, 215, 0.49) 52.46%, rgba(255, 156, 156, 0) 100%), radial-gradient(68.86% 68.86% at 94.55% 1.7%, #FADC90 0%, rgba(250, 220, 144, 0) 100%), linear-gradient(130.87deg, rgba(245, 115, 122, 0.78) 3.47%, rgba(245, 115, 122, 0) 77.25%); 
  // filter: blur(150px);
  background-repeat: repeat;
  background-position: top;
  // background-size: auto 100%;
  background-size:cover;
  overflow: visible;

}

.hero-skills {
  // margin-top: -500px;
}