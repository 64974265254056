.skills {
  // background-image: url('../assets/skills6.png');
  // background-size: cover;
  // background-position: top;
  // transform: scaleY(-1);
  // min-height: 470px;
  // background-color: $sectionbg;
  // background-color: #fdfdfd;
  // opacity: 1;
  // background-image: linear-gradient(#f7f7f7 1px, transparent 1px), linear-gradient(to right, #f7f7f7 1px, #fdfdfd 1px);
  // background-size: 20px 20px;

  // background-color: #F1F6FA;
  // opacity: 1;
  // background: radial-gradient(circle, transparent 20%, #F1F6FA 20%, #F1F6FA 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #F1F6FA 20%, #F1F6FA 80%, transparent 80%, transparent) 15px 15px, linear-gradient(#d6dadd 1.2000000000000002px, transparent 1.2000000000000002px) 0 -0.6000000000000001px, linear-gradient(90deg, #d6dadd 1.2000000000000002px, #F1F6FA 1.2000000000000002px) -0.6000000000000001px 0;
  // background-size: 30px 30px, 30px 30px, 15px 15px, 15px 15px;
  // margin-top: -650px;
  // z-index: 72423;
  // position: absolute;
  .content-wrapper {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 950px;
    width: 75vw;
    max-width: 1100px;
    padding: 0px 0px;

    .icons-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 25px;

      div {
        min-width: 95px;
        text-align: center;
        transition: transform 0.3s ease;

        i {
          font-size: 45px;
          // color: rgb(42, 40, 62);
          transition: 0.3 ease;

        }

        &:hover {
          transform: scale(1.1);
          // font-size: 60px;
        }

        img {
          max-width: 45px;
        }
      }
    }
  }
}