.section-containter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $sectionbg;
}

.experience {
  hr {

  }
  // background-color: $sectionbg;
  margin: 0 auto;
  padding-top: 50px;
  margin-top: 50px;
  // margin-left: 10px;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  // max-width: 1100px;
  // padding: 50px;
  padding-bottom: 0px;
  // margin: 0 auto;
  // margin-top: 20px;
  margin-bottom: 50px;
  max-width: 1200px;
  // background-color: rgba(255, 255, 255, 0.297);

  .content-wrapper {

    display: flex;
    justify-content: center;
    text-align: left;
  }

  .exp-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // border-radius: 200px;
    border: 1px solid aliceblue;

  }
  h3 {
    font-size: 22px;
    margin-bottom: 8px;
    font-family: 'Roboto';
  }
  h4 {
    font-size: 18px;
    color: $redText;
    // font-family: 'Roboto', sans-serif;
  }

  h5 {
    font-size: 1.2rem;
  }

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #5B5B5B;
    color: $darkText;
  }



img {
  max-width: 220px;
  // margin-top: 32px;
  text-align: right;
  border-radius: 200px;
  box-shadow: 0px 0px 0px 10px #FCD462;
}
}

.hide-section {
  display: none !important;
}